<template>
    <!-- 账户管理 -->

    <div class="main-pdding">
        <div class="main-box">
            <div class="title">
                <div class="t-label">
                    <label>附件管理</label>
                </div>
                <img src="@/assets/images/icon/arrow-rigth-black.png" />
                <div class="t-label current">
                    <label>{{ type == 1 ? "添加附件" : "修改附件" }}</label>
                </div>
            </div>
            <div class="setbox">
                <el-form ref="form" :model="form" label-width="150px">
                    <el-form-item label="上传文件">
              <el-upload
                class="upload-demo"
                :action="url"
                :limit="10"
                :on-success="uploadchange"
                :on-error="uploadchange"
                :show-file-list="false"
                list-type="picture"
              >
                <el-button size="small" type="primary">选择图片</el-button>
              </el-upload>
              <img
                v-if="form.url"
                :src="form.url"
                alt=""
                style="
                  width: 100px;
                  height: 100px;
                  margin: 5px 0;
                  vertical-align: top;"
              />
                    </el-form-item>
                    <el-form-item label="文件名称" >
                        <el-input v-model="form.name"></el-input>
                    </el-form-item>
                    <el-form-item label="是否显示">
                        <el-switch v-model="form.isshow"></el-switch>
                    </el-form-item>
                </el-form>
            </div>

            <div class="btn-box">
                <el-button @click="$router.push('/systemset/accountment')">取消</el-button>
                <el-button type="primary" @click="addInfo" v-if="type == 1">添加</el-button>
                <el-button type="primary" @click="editInfo" v-else-if="type == 2">修改</el-button>
            </div>
        </div>
    </div>
</template>

<script>
import { sessionGetKey } from "@/utils/sessionStorage.js";
import rules from "@/utils/rules";
export default {
    mounted() {
        this.platform_id = sessionGetKey("platformId");
        this.url =this.$uploadurl+ '?platform_id='+this.platform_id;
      
        if (this.$route.query.type) {
            this.type = this.$route.query.type;
        }
        if (this.$route.query.id) {
           
        }
    },
    data() {
        return {
            url:'',
            rules: rules,
            platform_id: "",
            type: "1",
            form: {
                id: "",
                isshow:false,
                url:''
            },
            roleData: [],
        };
    },
    methods: {
         uploadchange(res, file, fileList) {
     
      if (res.code == 0) {
        this.form.url = res.data;
      } else {
        this.$message("上传失败:" + file.response.msg);
      }
    },
       
        addInfo() {
            const that = this;
            console.log(that.form);

         

          
                    // that.$request({
                    //     url: that.$api.staff.addStaff,
                    //     method: "post",
                    //     data: {
                    //         account,
                    //         psw: password,
                    //         name: userName,
                    //         phone,
                    //         auth,
                    //         actor_id: actorId,
                    //     },
                    //     params: {
                    //         platform_id: that.platform_id,
                    //     },
                    // }).then((res) => {
                    //     that.$router.push("/systemset/accountment");
                    // });
               
        },
        editInfo() {
            const that = this;
            let { id, userName, account, password, conPws, phone, auth, actorId } = that.form;

            try {
                if (password !== conPws) {
                    throw new Error("确认密码不一致,请重新输入");
                }
            } catch (err) {
                this.$alert(err.message);
                return;
            }
            that.$refs.form.validate((valid) => {
                if (valid) {
                    that.$request({
                        url: that.$api.staff.editStaff,
                        method: "post",
                        data: {
                            id: id,
                            account,
                            psw: password,
                            name: userName,
                            phone,
                            auth,
                            actor_id: actorId,
                        },
                        params: {
                            platform_id: that.platform_id,
                        },
                    }).then((res) => {
                        that.$router.push("/systemset/accountment");
                    });
                }
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.btn-box {
    padding: 33px;
    background: #fff;
    margin-top: 30px;
}

.title {
    .t-label {
        display: inline-block;
        height: 35px;

        label {
            cursor: pointer;
            display: inline-block;
            vertical-align: middle;
            font-size: 20px;
            font-weight: 600;
        }
    }

    img {
        vertical-align: middle;
        width: 24px;
        height: 24px;
    }

    .current {
        border-bottom: 2px solid #4458fe;
        label {
            color: #4458fe;
        }
    }
}

.setbox {
    margin-top: 20px;
    color: #333333;

    .title {
        background: #edefff;
        height: 35px;
        line-height: 35px;
        padding-left: 15px;
    }
    /deep/.el-input__inner {
        width: 420px;
    }
    /deep/.input-id {
        margin-left: 20px;
        width: 100px;
    }
    /deep/.input-id .el-input__inner {
        width: 100px;
    }
    /deep/.el-form-item__content {
        // display: flex;
        // align-items: center;
    }
    .inputbox {
        //   margin-left: 15%;

        margin-top: 24px;
    }
}
</style>
